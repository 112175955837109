<template>
  <div class="hspl-wrap">
    <!-- <iframe src="https://html.mayidianjing.com/#/?from=mytq" width="100%" height="726px" frameborder="0"></iframe> -->

    <div class="hspl_tab_wrap">
      <div class="info">
        <el-row :gutter="40">
          <el-col :span="8">
            <div class="grid-content">
              <h3>真正免费(24小时在线)</h3>
              <p>1、服务器成本由蚂蚁特权官方承担</p>
              <p>2、真正长久稳定免费</p>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content">
              <h3>高配置游戏稳定流畅</h3>
              <p>1、与阿里云、腾讯云官方战略合作服务器</p>
              <p>2、全部采用高配置服务器，确保游戏比别人稳定流畅</p>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content">
              <h3>官方永不删挡</h3>
              <p>1、蚂蚁特权服务器官方运营永不删档</p>
              <p>2、随时随地上线秒进永不拥挤</p>
            </div>
          </el-col>
        </el-row>
      </div>

      <div class="table-box">
        <el-table :data="tableData" :header-cell-style="{ color: '#fff', background: '#3A4B46' }" border>
          <el-table-column prop="name" label="世界名称" align="center" width="260" />
          <el-table-column label="服务器地址（IP端口）" align="right" width="230">
            <template #default="scope">
              {{ scope.row.IP }}
              <el-button type="primary" size="mini" :id="'copy-' + scope.row.Id" v-clipboard:copy="scope.row.IP"
                v-clipboard:success="onCopy" v-clipboard:error="onError">复制</el-button>
              <!-- @click="copyText(`#copy-${scope.row.Id}`, true)" :data-clipboard-text="scope.row.IP" -->
            </template>
          </el-table-column>
          <el-table-column prop="num" label="支持在线人数" align="center" width="110" />
          <el-table-column prop="location" label="云服务器所在地" align="center" width="130" />
          <el-table-column prop="state" label="状态" align="center" width="60">
            <template #default="scope">
              <span :style="{ color: scope.row.state == '正常' ? '#fff' : '#FF2828' }">{{ scope.row.state }}</span>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="服务器参数设置" align="center" width="130">
            <template #default="scope">
              <el-button type="text" @click="handleDelete(scope.row.params)">查看设置</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <!-- 查看设置 -->
    <el-dialog custom-class="setting-visible-dialog" :visible.sync="dialogTableVisible" title="服务器参数设置信息" width="620px">
      <el-table :data="gridData" :show-header="false" border height="300">
        <el-table-column property="info" align="center" />
        <el-table-column property="config" align="center" />
        <el-table-column property="val" align="center" />
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import Clipboard from 'clipboard'
import { ElMessage } from 'element-ui'

export default {
  data() {
    return {
      tableData: [],
      dialogTableVisible: false,
      gridData: []
    }
  },
  created() {

    // let from = this.$route.query['from'];
    // if (from == 'mytq') {
    //   this.$statistic(["A", "特权", "幻兽帕鲁服务器页面", "访问量"]);
    // } else if (from == 'steamok') {
    //   this.$statistic(["A", "steamok", "幻兽帕鲁服务器页面", "访问量"]);
    // } else {
    //   this.$statistic(["A", "其他", "幻兽帕鲁服务器页面", "访问量"]);
    // }
  },
  mounted() {
    this.tableData = data
    
    this.$statistic(["A", "特权", "幻兽帕鲁服务器页面", "访问量"]);
  },
  methods: {
    // 复制成功时的回调函数
    onCopy(e) {
      this.$message({
        type: "success",
        message: "内容已复制到剪切板！",
      });
    },
    // 复制失败时的回调函数
    onError(e) {
      this.$message.error("抱歉，复制失败！");
      this.$message({
        type: "error",
        message: "抱歉,复制失败！",
      });
    },
    handleDelete(params) {
      this.gridData = params
      console.log(this.gridData)
      this.dialogTableVisible = true
    }
  }
}
</script>

<style lang="scss" scoped>
.hspl-wrap {
  width: 1080px;
  height: 716px;
  background-image: url('../../assets/img/bg_1.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  overflow: hidden;

  .hspl_tab_wrap {
    padding-top: 160px;

    .info {
      width: 840px;
      margin: 0 auto 50px;

      .grid-content {
        height: 106px;
        border-top: 3px solid #FFFFFF;
        border-bottom: 3px solid #FFFFFF;
        background: rgba(0, 0, 0, 0.5);
        color: #fff;
        padding: 6px;
        box-sizing: border-box;
        text-align: left;
        line-height: 22px;

        h3 {
          font-size: 18px;
          text-align: center;
          margin-bottom: 5px;
        }

        p {
          font-size: 12px;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.setting-visible-dialog {
  .el-dialog__body {
    padding: 20px;
  }

  // td.el-table__cell {
  // background: rgba(0, 0, 0, 0.8);
  // border-bottom: 1px solid green !important;
  // }
}

.table-box {
  .el-table {
    width: 920px;
    margin: 0 auto;
  }

  .el-table__body tr.hover-row.current-row>td,
  .el-table__body tr.hover-row.el-table__row--striped.current-row>td,
  .el-table__body tr.hover-row.el-table__row--striped>td,
  .el-table__body tr.hover-row>td {
    background-color: transparent;
  }

  .el-table__body tr>td {
    color: #fff !important;
  }

  .el-table--border {
    border: 1px solid #bbb;
  }

  .el-table--border .el-table__cell,
  .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
    padding: 6px 0;
    border-right: 1px solid #bbb;
  }

  .el-table__body-wrapper {
    background: rgba(0, 0, 0, 0.8);
  }

  .el-table__fixed-right-patch {
    background: rgba(0, 0, 0, 0.8);
  }

  .el-table__fixed-right::before,
  .el-table__fixed::before {
    background: transparent;
  }

  .el-button--text {
    border: none;
    color: #31deff;
    text-decoration: underline;
  }
}
</style>